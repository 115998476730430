// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { operationalTypes } from "@constants/types-constants";
import { convertIsoDate } from "@helpers/date-helper";

export const appDataEntryFormSlice = createSlice({
  name: "appDataEntryForm",
  initialState: {
    period: {
      date: convertIsoDate(new Date())
    },
    productions: [
      {
        providerId: "",
        name: "",
        email: "",
        goal: "",
        production: "",
        appointments: "",
        projection: ""
      }
    ],
    collections: {
      check: "",
      cash: "",
      merchant: "",
      eft: ""
    },
    patients: {
      referral: "",
      left: "",
      online: "",
      other: ""
    },
    "missed-appointments": {
      rescheduled: "",
      noShow: ""
    },

    isValid: true,
    isError: false
  },
  reducers: {
    changeFormValue: (state, action) => {
      state[action.payload.type][action.payload.field] = action.payload.value;
    },
    changeProductionFormValue: (state, action) => ({
      ...state,
      [action.payload.type]: state[action.payload.type].map(item => {
        if (item.providerId === action.payload.id) {
          return {
            ...item,
            [action.payload.field]: action.payload.value
          };
        }
        return item;
      })
    }),
    setFormValues: (state, action) => {
      switch (action.payload.type) {
        case operationalTypes.PERIOD: {
          state[action.payload.type] = {
            date: action.payload.data.date
          };
          break;
        }
        case operationalTypes.PRODUCTIONS: {
          state[action.payload.type] = action.payload.data.map(item => ({
            providerId: item.provider.id,
            name: item.provider.name,
            email: item.provider.email,
            goal: +item.provider.goal,
            production: +item.production,
            appointments: +item.appointments,
            projection: +item.projection
          }));
          break;
        }
        case operationalTypes.COLLECTIONS: {
          state[action.payload.type] = {
            check: +action.payload.data.check,
            cash: +action.payload.data.cash,
            merchant: +action.payload.data.merchant,
            eft: +action.payload.data.eft
          };
          break;
        }
        case operationalTypes.PATIENTS: {
          state[action.payload.type] = {
            referral: +action.payload.data.referral,
            left: +action.payload.data.left,
            online: +action.payload.data.online,
            other: +action.payload.data.other
          };
          break;
        }
        case operationalTypes.MISSED_APPOINTMENTS: {
          state[action.payload.type] = {
            rescheduled: +action.payload.data.rescheduled,
            noShow: +action.payload.data.noShow
          };
          break;
        }
      }
    },
    setFormIsValid: (state, action) => {
      state.isValid = action.payload;
    },

    setShowErrorMessage: (state, action) => {
      state.isError = action.payload;
    }
  }
});

export const { changeFormValue, setShowErrorMessage, setFormIsValid, setFormValues, changeProductionFormValue } =
  appDataEntryFormSlice.actions;

export default appDataEntryFormSlice.reducer;
