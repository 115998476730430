import Box from "@mui/material/Box";
import Image from "next/image";
import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { boxStyles, buttonStyles, descriptionStyles } from "@constants/empty-styles-screens";
import Link from "next/link";

const EmptyScreen = props => {
  const { src, title, description, buttonText, link, buttonType, onClick, isDisabled } = props;

  return (
    <Box sx={boxStyles}>
      <Image src={src} alt='' width='100%' height='100%' />
      {title}
      <Typography sx={descriptionStyles}>{description}</Typography>

      {buttonText &&
        (buttonType === "link" ? (
          <Button
            href={link || "/"}
            component={Link}
            sx={buttonStyles}
            fullWidth
            disabled={isDisabled}
            color='primary'
            variant='contained'
          >
            {buttonText}
          </Button>
        ) : (
          <Button onClick={onClick} sx={buttonStyles} fullWidth color='primary' variant='contained'>
            {buttonText}
          </Button>
        ))}
    </Box>
  );
};

export default EmptyScreen;
