export const INVALID_TOKEN =
  "Token for this Google Account is either invalid, expired or revoked by user. Please try connecting your Google Account to the Google Search Console API again.";
export const NOT_LOGGED_IN = "Not logged in.";
export const PASSWORD_NOT_SELECTED = "Password not selected.";
export const LOCATION_NOT_SELECTED = "MyBusiness location not selected.";
export const WEBSITE_NOT_SELECTED = "Website not selected.";
export const PROPERTY_NOT_SELECTED = "Analytics GA4 property not selected.";
export const PAGE_NOT_FOUND = "Facebook Page not found.";
export const SOCIAL_NOT_SELECTED = "Social Media page not selected.";
export const LOCATION_NOT_FOUND = "Location not found.";
export const PROPERTY_NOT_FOUND = "Property not found.";
export const COMPANY_NOT_SELECTED = "Company not selected.";
export const YOU_DO_NOT_WORK_AT_THIS_COMPANY = "you do not work at this company ";
export const SELECT_DATE_DATA_ENTRY = "Please select date before proceeding with saving productions.";
export const DYNAMICS365_NOT_CONNECTED =
  "This company is not connected with a Dynamics 365 App. You need to request an access to this feature.";
export const DYNAMICS365_WAITING_APPROVAL =
  "This company is not connected with a Dynamics 365 App. You have already requested an access to this feature. Please wait for our team to approve it.";
export const FACEBOOK_TOKEN_EXPIRED = "Access token has expired. Please reconnect your Facebook account.";
