import { createSlice } from "@reduxjs/toolkit";
import { fetchAdsData, fetchFacebookAdvertisers, fetchGoogleAdvertisers, fetchKeywords } from "./action";
import { handleError } from "@helpers/store-helper";

const setAdsData = (state, action) => {
  state.isAdsDataLoading = false;
  state.adsAnalyticsData = action?.payload?.analytics;
  state.adsCampaignData = action?.payload?.campaigns;
  state.adsKeywordsData = action?.payload?.keywords;
  state.adsData = action?.payload;
};

const setError = (state, action) => {
  state.isAdsDataLoading = false;
  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

export const appAdvertismentSlice = createSlice({
  name: "appAdvertisment",
  initialState: {
    googleAdvertisers: {},
    facebookAdvertisers: {},
    adsData: {},
    adsAnalyticsData: {},
    adsCampaignData: {},
    adsKeywordsData: {},
    isAdsDataLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGoogleAdvertisers.fulfilled, (state, action) => {
        state.googleAdvertisers = action.payload;
      })
      .addCase(fetchGoogleAdvertisers.pending, state => {})
      .addCase(fetchGoogleAdvertisers.rejected, setError)
      .addCase(fetchFacebookAdvertisers.fulfilled, (state, action) => {
        state.facebookAdvertisers = action.payload;
      })
      .addCase(fetchFacebookAdvertisers.pending, state => {})
      .addCase(fetchFacebookAdvertisers.rejected, state => {})
      .addCase(fetchAdsData.fulfilled, setAdsData)
      .addCase(fetchAdsData.pending, state => {
        state.isAdsDataLoading = true;
      })
      .addCase(fetchAdsData.rejected, setError);
    // .addCase(fetchKeywords.pending, state => {})
    // .addCase(fetchKeywords.rejected, state => {})
    // .addCase(fetchKeywords.fulfilled, (state, action) => {
    //   state.adsKeywordsData = action.payload;
    // });
  }
});

export default appAdvertismentSlice.reducer;
