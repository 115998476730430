export const operationalTypes = {
  PERIOD: "period",
  PRODUCTIONS: "productions",
  ALL_PROVIDERS: "all-providers",
  ACTIVE_PROVIDERS: "active-providers",
  DEACTIVATED_PROVIDERS: "deactivated-providers",
  COLLECTIONS: "collections",
  PATIENTS: "patients",
  MISSED_APPOINTMENTS: "missed-appointments",
  APPOINTMENTS: "appointments"
};

export const SEO_TYPES = {
  VISITORS_BY_CITY: "visitorsByCity",
  YOUR_VISITORS: "yourVisitors",
  KEYWORDS: "keywords-clicks",
  IMPRESSIONS: "keywords-impressions"
};

export const VISITORS_TYPES = {
  ORGANIC: "Organic Search",
  PAID: "Paid Search"
};

export const RESEND_TYPES = {
  VERIFY_EMAIL: "verify-email",
  RESET_PASSWORD: "reset-password"
};

export const categoryTitles = {
  marketing: "Marketing",
  operational: "Operational",
  providers: "Providers & Roles",
  account: "Account Settings"
};

export const COLLECTIONS_TYPES = {
  cash: "Cash",
  merchant: "Merchant",
  eft: "EFT",
  check: "Check"
};

export const socialTypes = {
  FOLLOWERS: "Followers",
  ENGAGEMENT: "Engagement",
  VIEWS: "Views",
  REACTIONS: "Reactions",
  CTA: "CTA"
};

export const advertismentTypes = {
  CONVERSIONS: "results",
  BUDGET: "budget",
  CLICKS: "clicks",
  IMPRESSIONS: "impressions"
};

export const campaignTypes = {
  CONVERSIONS: "results",
  BUDGET: "budget",
  CLICKS: "clicks",
  IMPRESSIONS: "impressions"
};

export const CARD_TYPES = {
  RATING: "Rating",
  LIST: "List",
  REVIEWS: "Reviews"
};

export const REVIEW_FILTER_TYPES = {
  RATING: "rating",
  SOCIAL: "social",
  SORT_BY: "sort"
};

export const accountNavList = [
  {
    action: "read",
    subject: "acl-page",
    value: "account",
    title: "Account",
    icon: "tabler:users"
  },
  {
    value: "company",
    title: "Company",
    icon: "tabler:briefcase"
  },
  {
    action: "read",
    subject: "acl-page",
    value: "security",
    title: "Security",
    icon: "tabler:lock"
  },
  {
    action: "read",
    subject: "acl-page",
    value: "notifications",
    title: "Notifications",
    icon: "tabler:bell"
  },
  {
    value: "connections",
    title: "Connections",
    icon: "tabler:link"
  }
];

export const BLUE_SCHEMA = ["#133fc2", "#1E54F5", "#7C9AF4", "#C1CFFE", "#EBF0FF"];
export const DIFF_NO_DATA = "No data to display";
export const DIFF_UNKNOWN = "unknown";
export const NO_TICKETS = "There are  no active issues for the selected date";
export const DYNAMICS365_STATUS =
  "To start using the Dynamics 365 feature, please request access by clicking the button below. Once your request is submitted, we'll initiate the connection to our system for you.";
export const DYNAMICS365_APPROVAL =
  "You've successfully requested access to Dynamics 365. Please wait while our team processes your request and connects you to the system.";

//FILTERS
export const SPECIALTY_LIST = [
  "General Dentist",
  "Orthodontist",
  "Endodontist",
  "Periodontist",
  "Oral Surgeon",
  "Hygienist"
];
export const POSITIONS = ["CEO", "CFO", "CTO", "Team Lead", "Member"];
export const STATUSES = ["active", "inactive"];

export const SHORT_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const SOCIAL_REVIEW_FILTERS = ["Google", "Yelp", "Facebook", "Instagram"];
export const BUSINESS_TYPES = ["commercial", "private", "self-employed", "self-owned"];
export const REVIEW_FILTERS_BY = ["ASC", "DESC"];

//STEPS
export const ONBOARDING_DATA_STEPS = [
  {
    icon: "tabler:user",
    title: "Personal",
    subtitle: "Enter Details",
    isBordered: true,
    heading: "Enter your personal details",
    description: "Please provide us with your basic information"
  },
  {
    icon: "tabler:building",
    title: "Company",
    subtitle: "Enter Details",
    isBordered: true,
    heading: "Provide your company information",
    description: "Tell us more about your reviews"
  },
  {
    icon: "tabler:atom",
    title: "Sources",
    subtitle: "Connect",
    heading: "Connect your data sources",
    description: "Enable data tracking for better insights"
  },
  {
    icon: "tabler:flag",
    title: "Finish",
    heading: "",
    description: ""
  }
];

export const DAILY_DATA_STEPS = [
  {
    title: "Choose Period"
  },
  {
    title: "Production"
  },
  {
    title: "Collection"
  },
  {
    title: "Patients"
  },
  {
    title: "Reschedule & No Shows"
  }
];
