import { styled } from "@mui/material";
import DatePicker from "react-datepicker";
import MuiStep from "@mui/material/Step";
import Button from "@mui/material/Button";

export const formBoxStyles = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  "@media (max-width:900px)": { flexDirection: "column" }
};

export const formControlStyles = {
  width: "100%",
  "@media (max-width:900px)": { mb: 4, width: "auto" }
};

export const formControlSecondStyles = {
  ml: 5,
  width: "100%",
  "@media (max-width:900px)": { mb: 4, ml: 0, width: "auto" }
};

export const labelStyles = {
  color: "#4B465C",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "15px",
  marginBottom: "4px",
  marginLeft: "0px"
};

export const inputStyles = {
  "& .MuiOutlinedInput-input": {
    height: "6px"
  },
  height: "38px"
};

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    padding: "0px 10px",
    maxWidth: "238px",
    height: "38px"
  },
  "& .demo-space-x>*": {
    marginTop: "1rem",
    marginRight: "0px"
  }
}));

export const Step = styled(MuiStep)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  "&:first-of-type": {
    paddingLeft: 0
  },
  "&:last-of-type": {
    paddingRight: 0
  },
  "& .MuiStepLabel-iconContainer": {
    display: "none"
  },
  "& .step-subtitle": {
    color: `${theme.palette.text.disabled} !important`
  },
  "& + svg": {
    color: theme.palette.text.disabled
  },
  "&.Mui-completed .step-title": {
    color: theme.palette.text.disabled
  },
  "&.Mui-completed + svg": {
    color: theme.palette.primary.main
  },
  "@media (max-width:925px)": {
    "&.MuiStep-horizontal + svg": {
      display: "none"
    },
    "&.MuiStep-horizontal": {
      paddingLeft: "0",
      paddingTop: "10px"
    }
  }
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  height: "38px",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "18px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: theme.palette.success.main
  }
}));

export const TypographyStyles = {
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: "500",
  color: "#4B465C",
  marginBottom: "26px"
};
