// ** Next Imports
import Link from "next/link";
import { useRouter } from "next/router";

// ** MUI Imports
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

// ** Configs Import
import themeConfig from "src/configs/themeConfig";

// ** Custom Components Imports
import UserIcon from "src/layouts/components/UserIcon";
import Translations from "src/layouts/components/Translations";
import CanViewNavLink from "src/layouts/components/acl/CanViewNavLink";

// ** Util Imports
import { hexToRGBA } from "@core/utils/hex-to-rgba";
import { handleURLQueries } from "@core/layouts/utils";

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: "100%",
  marginLeft: theme.spacing(3.5),
  marginRight: theme.spacing(3.5),
  borderRadius: theme.shape.borderRadius,
  transition: "padding-left .25s ease-in-out, padding-right .25s ease-in-out",
  "&.active": {
    "&, &:hover": {
      boxShadow: `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
      background: "linear-gradient(90.73deg, #2575FC -11.16%, #6A11CB 144.89%)"
    },

    //sidebar color
    // //sidebar mauwal color
    // `linear-gradient(72.47deg, ${
    //   theme.direction === 'ltr' ? theme.palette.primary.main : hexToRGBA(theme.palette.primary.main, 0.7)
    // } 22.16%, ${
    //   theme.direction === 'ltr' ? hexToRGBA(theme.palette.primary.main, 0.7) : theme.palette.primary.main
    // } 76.47%)`,
    // '&.Mui-focusVisible': {
    //   background: `linear-gradient(72.47deg, ${theme.palette.primary.dark} 22.16%, ${hexToRGBA(
    //     theme.palette.primary.dark,
    //     0.7
    //   )} 76.47%)`
    // }

    // }
    "& .MuiTypography-root, & svg": {
      color: `${theme.palette.common.white} !important`
    }
  }
}));

const MenuItemTextMetaWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" })
}));

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}) => {
  // ** Hooks
  const theme = useTheme();
  const router = useRouter();

  // ** Vars
  const { mode, navCollapsed } = settings;
  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  const conditionalColors = () => {
    if (mode === "semi-dark") {
      return {
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`
        },
        "& .MuiTypography-root, & svg": {
          color: `rgba(${theme.palette.customColors.dark}, 0.6)`
        }
      };
    } else
      return {
        "& .MuiTypography-root, & svg": {
          color: "text.secondary"
        }
      };
  };

  const isNavLinkActive = () => {
    if (router.pathname === item.path || handleURLQueries(router, item.path)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <CanViewNavLink navLink={item}>
      <ListItem
        disablePadding
        className='nav-link'
        disabled={item.disabled || false}
        sx={{ mt: 1, px: "0 !important" }}
      >
        <MenuNavLink
          component={Link}
          {...(item.disabled && { tabIndex: -1 })}
          className={isNavLinkActive() ? "active" : ""}
          href={item.path === undefined ? "/" : `${item.path}`}
          {...(item.openInNewTab ? { target: "_blank" } : null)}
          onClick={e => {
            if (item.path === undefined) {
              e.preventDefault();
              e.stopPropagation();
            }
            if (navVisible) {
              toggleNavVisibility();
            }
          }}
          sx={{
            fontWeight: 450,
            py: 2,
            ...conditionalColors(),
            ...(item.disabled ? { pointerEvents: "none" } : { cursor: "pointer" }),
            px: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 22 - 28) / 8 : 4
          }}
        >
          <ListItemIcon
            sx={{
              transition: "margin .25s ease-in-out",
              ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2 }),
              ...(parent ? { ml: 1.5, mr: 3.5 } : {}),
              "& svg": {
                fontSize: "0.625rem",
                ...(!parent ? { fontSize: "1.375rem" } : {}),
                ...(parent && item.icon ? { fontSize: "0.875rem" } : {})
              }
            }}
          >
            <UserIcon icon={icon} />
          </ListItemIcon>

          <MenuItemTextMetaWrapper
            sx={{
              ...(isSubToSub ? { ml: 2 } : {}),
              ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
            }}
          >
            {/* <Typography variant='h1' sx={{fontWeight:900,border:"1px solid gold"}}
              {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                noWrap: true
              })}
            > */}

            {/* siderbar font set */}
            <Typography sx={{ fontWeight: "450 !important", color: "#6F6B7D" }}>{item.title} </Typography>
            {/* </Typography> */}
            {item.badgeContent ? (
              <Chip
                label={item.badgeContent}
                color={item.badgeColor || "primary"}
                sx={{
                  height: 20,
                  fontWeight: 500,
                  "& .MuiChip-label": { px: 1.5, textTransform: "capitalize" }
                }}
              />
            ) : null}
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </ListItem>
    </CanViewNavLink>
  );
};

export default VerticalNavLink;
