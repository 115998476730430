import axios from "axios";
import { analyticsPath } from "@constants/base-urls";

// Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

// Service methods
const caseManagementService = {
  fetchAllCases: async (startDate, endDate) => {
    return await axiosInstance.get(`/cases?${startDate}${endDate}`);
  },
  resetCasesCache: async () => {
    return await axiosInstance.get("/cases/reset-cache");
  },
  createCase: async payload => {
    return await axiosInstance.post("/cases", payload);
  },
  removeDynamicsAccess: async () => {
    return await axiosInstance.delete("/cases/remove-access");
  },
  requestDynamicsAccess: async () => {
    return await axiosInstance.post("/cases/request-access");
  }
};

export default caseManagementService;
