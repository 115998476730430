// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
// import chat from '@store/apps/chat'
import analytics from "@store/apps/analytics/slice";
import auth from "@store/apps/auth";
import dataEntryForm from "@store/apps/auth/daily-data-entry";
import email from "@store/apps/email";
import mapBox from "@store/apps/mapbox";
import operational from "@store/apps/operational";
import onboardingForm from "@store/apps/operational/onboarding";
import reporting from "@store/apps/reporting";
import business from "@store/apps/reviews";
import roles from "@store/apps/roles";
import socialMedia from "@store/apps/social-media/slice";
import daysFilter from "@store/apps/sort";
import signUpForm from "@store/apps/signup";
import advertisment from "@store/apps/advertisment/slice";
import caseManagement from "@store/apps/cases/slice";
import reviews from "@store/apps/ai/slice";
export const store = configureStore({
  reducer: {
    email,
    daysFilter,
    onboardingForm,
    dataEntryForm,
    operational,
    analytics,
    socialMedia,
    mapBox,
    roles,
    business,
    auth,
    reporting,
    signUpForm,
    advertisment,
    caseManagement,
    reviews
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
