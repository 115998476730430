import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import { analyticsPath } from "@constants/base-urls";
import { LOCATION_NOT_SELECTED } from "@constants/response-messages";
import { handleError } from "@helpers/store-helper";
import axios from "axios";
import moment from "moment";
import { fetchBusinessLocations } from "../auth";

export const fetchBusiness = createAsyncThunk(
  "appBusiness/fetchBusiness",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end
        ? moment(end).add(1, "days").utc().format("YYYY-MM-DD") // Add 1 day to the end date
        : utcStart
        ? utcStart
        : "";

      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await axios.get(`${analyticsPath}/reviews?${startDate}${endDate}&data[]=google`, {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        });

        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message === LOCATION_NOT_SELECTED) {
        dispatch(fetchBusinessLocations());
      }
      return rejectWithValue(error);
    }
  }
);

const setError = (state, action) => {
  state.status = false;
  state.isLoading = false;
  state.isBusinessLoading = false;
  state.isBusinessFiltersLoading = false;

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

const setBusinessData = (state, action) => {
  state.isBusinessLoading = false;
  state.isBusinessFiltersLoading = false;
  state.errorMessage = "";

  state.business = {
    reviews: action.payload?.reviews,
    header: action.payload?.analytics?.[0]?.header,
    reviewsReports: action.payload?.analytics?.[0]?.reports?.find(item => item?.name === "reviews"),
    ratingReports: action.payload?.analytics?.[0]?.reports?.find(item => item?.name === "ratings"),
    reviewsGraph: action.payload?.analytics?.[0]?.graphs?.find(item => item?.name === "reviews")?.data,
    ratingsGraph: action.payload?.analytics?.[0]?.graphs?.find(item => item?.name === "ratings")?.data
  };
};

export const appBusinessSlice = createSlice({
  name: "appBusiness",
  initialState: {
    redirectUrl: "",
    business: [],
    isBusinessFiltersLoading: false,
    isBusinessLoading: false,
    status: null,
    errorMessage: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBusiness.fulfilled, setBusinessData)
      .addCase(fetchBusiness.pending, (state, action) => {
        state.isBusinessLoading = true;
        state.errorMessage = "";
        state.isBusinessFiltersLoading = true;
      })
      .addCase(fetchBusiness.rejected, setError);
  }
});

export default appBusinessSlice.reducer;
