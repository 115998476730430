import axios from "axios";
import { analyticsPath } from "@constants/base-urls";

// Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: analyticsPath,
  withCredentials: true,
  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
});

// Service methods
const socialMediaService = {
  fetchSocialMedia: async (startDate, endDate) => {
    return await axiosInstance.get(`/social-media?${startDate}${endDate}&data[]=facebook&data[]=instagram`, {
      withCredentials: true,
      adapter: ["xhr", "http", function myCustomAdapter(config) {}]
    });
  }
};

export default socialMediaService;
