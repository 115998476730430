// ** React Imports
import { forwardRef } from "react";

// ** MUI Imports
import MuiAvatar from "@mui/material/Avatar";
import { lighten, useTheme } from "@mui/material/styles";

// ** Hooks Imports
import useBgColor from "@core/hooks/useBgColor";

const Avatar = forwardRef((props, ref) => {
  // ** Props
  const { sx, src, skin, color } = props;

  // ** Hook
  const theme = useTheme();
  const bgColors = useBgColor();

  const getAvatarStyles = (skin, skinColor) => {
    let avatarStyles;

    switch (skin) {
      case "primary":
        avatarStyles = {
          color: bgColors["primaryLight"].color,
          backgroundColor: lighten(theme.palette.primary.main, 0.88)
        };
        break;
      case "light":
        avatarStyles = { ...bgColors[`${skinColor}Light`] };
        break;
      case "light-static":
        avatarStyles = {
          color: bgColors[`${skinColor}Light`].color,
          backgroundColor: lighten(theme.palette[skinColor].main, 0.88)
        };
        break;

      default:
        avatarStyles = { ...bgColors[`${skinColor}Filled`] };
        break;
    }

    return avatarStyles;
  };

  const colors = {
    primary: getAvatarStyles(skin, "primary"),
    secondary: getAvatarStyles(skin, "secondary"),
    secondaryDark: getAvatarStyles(skin, "secondaryDark"),
    success: getAvatarStyles(skin, "success"),
    error: getAvatarStyles(skin, "error"),
    warning: getAvatarStyles(skin, "warning"),
    info: getAvatarStyles(skin, "info")
  };

  return <MuiAvatar ref={ref} {...props} sx={!src && skin && color ? Object.assign(colors[color], sx) : sx} />;
});
Avatar.defaultProps = {
  skin: "filled",
  color: "primary"
};

export default Avatar;
