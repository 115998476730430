// ** MUI Imports
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
  height: "100vh",

  // For V1 Blank layout pages
  "& .content-center": {
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5)
  },

  // For V2 Blank layout pages
  "& .content-right": {
    display: "flex",
    minHeight: "100vh",
    overflowX: "hidden",
    position: "relative"
  }
}));

const BlankLayout = ({ children }) => {
  return (
    <BlankLayoutWrapper className='layout-wrapper'>
      <Box className='app-content' sx={{ overflow: "hidden", minHeight: "100vh", position: "relative" }}>
        {children}
      </Box>
    </BlankLayoutWrapper>
  );
};

export default BlankLayout;
