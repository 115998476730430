export const titleStyles = {
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "24px",
  marginTop: "27px"
};

export const descriptionStyles = {
  fontWeight: 450,
  fontSize: "13px",
  lineHeight: "20px",
  marginTop: "6px"
};

export const buttonStyles = {
  fontWeight: 450,
  fontSize: "15px",
  lineHeight: "19px",
  height: "39px",
  width: "auto",
  marginTop: "24px"
};

export const boxStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "470px",
  textAlign: "center",
  margin: "0 auto",
  height: "100%",
  justifyContent: "center"
};
