//POPUP
export const UPDATED_SUCCESS_TITLE = "User Updated Successfully!";
export const UPDATED_SUCCESS_TEXT = "The user information has been successfully updated.";
export const ADDED_SUCCESS_TITLE = "User added Successfully!";
export const ROLES_SUCCESS_TITLE = "Roles updates Successfully!";
export const ROLES_ADDED_SUCCESS_TITLE = "Roles added Successfully!";
export const ROLE_DELETED_SUCCESS_TITLE = "Role deleted Successfully!";
export const ADDED_SUCCESS_TEXT = "We've added the user and sent an email with further instructions.";
export const UPDATED_PROVIDER_SUCCESS_TITLE = "Provider Updated Successfully";
export const DELETED_PROVIDER_SUCCESS_TITLE = "Provider Deleted Successfully";
export const ADDED_PROVIDER_SUCCESS_TITLE = "Provider added Successfully!";
export const DELETED_SUCCESS_TEXT =
  "You've successfully deleted the selected user from the system. They have been removed from the platform.";
export const DELETED_SUCCESS_TITLE = "User Successfully Deleted!";

//MODALS
export const LEAVE_COMPANY =
  "This action will permanently delete the you from the company. Are you sure you want to proceed with the deletion? This action cannot be undone.";
export const DELETE_PROVIDER =
  "This action will permanently delete the provider from the system. Are you sure you want to proceed with the deletion? This action cannot be undone.";
export const DELETE_USER =
  "This action will permanently delete the user from the system. Are you sure you want to proceed with the deletion? This action cannot be undone.";
export const DELETE_ROLE =
  "This action will permanently delete the role from the system. Are you sure you want to proceed with the deletion? This action cannot be undone.";
export const DELETE_ACCOUNT_TITLE = "Are you sure you want to delete your MyVigorant account?";
export const DELETE_ACCOUNT_FIRST_PARAGRAPH =
  "By deleting your account, you will lose access to all your data and settings, and will not be able to log in again in the future. If you would like to save your data or export any important information before proceeding, please do so now.";
export const DELETE_ACCOUNT_SECOND_PARAGRAPH =
  'To delete your account, please type "DELETE" in the field below and click the "Yes, delete my account" button. If you change your mind, you can click "Cancel" to keep your account.';
export const DELETE_ACCOUNT_LABEL = "Type the word “DELETE” below (no quotes, all capital letters)";

//BUTTONS
export const BUTTON_DATA_SOURCES = "Connect Data Sources";
export const BUTTON_ADD_PROVIDER = "Add Provider";
export const BUTTON_ENTER_DATA = "Enter Data Now";

//TOOLTIPS
export const seoToolTip =
  "Get insights into your competitors' performance with an average ranking position, which helps you identify areas for improvement and stay ahead.";
export const visitorsToolTip =
  "Use the interactive line graph to visualize the distribution of your website visitors, comparing organic and paid traffic sources.";
export const analysisToolTip =
  "Track your competitors' average ratings over time,\n" +
  "with the line chart to see how your reviews compare\n" +
  "in customer satisfaction. Keep up the great work!";
export const visitorsByCityTooltip =
  "Explore where your website visitors are coming from with an interactive pie chart that highlights the top cities contributing the most to your website traffic.";
//EMPTY SCREEN
export const OVERVIEW_TITLE = "Take Your Marketing to the Next Level";
export const OVERVIEWS_DESCRIPTION =
  "Connect your data sources to unlock valuable insights and optimize your marketing strategies. Optimize SEO, analyze campaigns, manage your online reputation, and outshine the competition.";
export const SEO_TITLE = "Optimize Your Website's SEO ️";
export const SEO_DESCRIPTION =
  "Improve your website's search engine optimization and boost organic traffic. Connect your SEO tools to gather valuable data and optimize your online presence. Let's maximize your SEO efforts!";
export const CUSTOMER_REPUTATION_TITLE = "Enhance Your Online Reputation";
export const CUSTOMER_REPUTATION_DESCRIPTION =
  "Start collecting and managing customer reviews to enhance your reviews's online presence. Engage with your customers and gather feedback to improve your reputation.";
export const ADVERTISEMENT_TITLE = "Take Your Ads to the Next Level";
export const ADVERTISEMENT_DESCRIPTION =
  "Connect your advertising accounts to track and optimize your paid campaigns. Drive targeted traffic, maximize ROI, and achieve your marketing goals.";
export const SOCIAL_MEDIA_TITLE = "Track Your Social Media Performance";
export const SOCIAL_MEDIA_DESCRIPTION =
  "Monitor and analyze your social media reach and engagement. Connect your social media accounts to access valuable metrics and insights. Stay connected with your audience and optimize your social media strategy.";
export const DASHBOARD_TITLE = "Elevate Your Operations to New Heights";
export const DASHBOARD_DESCRIPTION =
  "Take control of your practice's performance by analyzing production, collections, and appointments. Set goals for your providers and watch your practice thrive.";
export const DATA_ENTRY_DESCRIPTION =
  "Looks like you haven't added any providers yet. Before entering daily data, please add providers to enter their production, collection, and appointments";
export const PROVIDERS_TITLE = "No Providers Found";
export const PROVIDERS_DESCRIPTION =
  "You haven't added any providers yet. Start by adding a new provider to track their production and set goals.";
export const NOTIFICATION_TITLE = "No Notifications Yet";
export const NOTIFICATION_DESCRIPTION =
  "Don't worry, notifications on successful campaigns, top social media posts, customer feedback, and essential metric changes are on the horizon.";
export const GOOGLE_CAMPAIGN = "Google Compaign Perfomance";
export const FB_INSTA_CAMPAIGN = "Facebook/Instagram Campaign Performance";
export const KEYWORD_PERFORMANCE = "Perfoming Keywords";
