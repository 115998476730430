const Confused = () => {
  return (
    <svg width='65' height='64' viewBox='0 0 65 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M62.25 32C62.25 48.5685 48.8185 62 32.25 62C15.6815 62 2.25 48.5685 2.25 32C2.25 15.4315 15.6815 2 32.25 2C48.8185 2 62.25 15.4315 62.25 32Z'
        fill='#FF9F43'
        fillOpacity='0.16'
      />
      <path
        d='M62.25 32C62.25 48.5685 48.8185 62 32.25 62C15.6815 62 2.25 48.5685 2.25 32C2.25 15.4315 15.6815 2 32.25 2C48.8185 2 62.25 15.4315 62.25 32Z'
        stroke='#FF9F43'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M62.25 32C62.25 48.5685 48.8185 62 32.25 62C15.6815 62 2.25 48.5685 2.25 32C2.25 15.4315 15.6815 2 32.25 2C48.8185 2 62.25 15.4315 62.25 32Z'
        stroke='black'
        strokeOpacity='0.2'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.2496 25.3337H22.2829'
        stroke='#FF9F43'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.2496 25.3337H22.2829'
        stroke='black'
        strokeOpacity='0.2'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.2496 25.3337H42.2829'
        stroke='#FF9F43'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.2496 25.3337H42.2829'
        stroke='black'
        strokeOpacity='0.2'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.917 42C26.1114 44.2396 29.1149 45.5017 32.2503 45.5017C35.3858 45.5017 38.3893 44.2396 40.5837 42'
        stroke='#FF9F43'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.917 42C26.1114 44.2396 29.1149 45.5017 32.2503 45.5017C35.3858 45.5017 38.3893 44.2396 40.5837 42'
        stroke='black'
        strokeOpacity='0.2'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Confused;
