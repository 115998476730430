// ** Mock Adapter
import mock from "src/@fake-db/mock";

const searchData = [
  {
    id: 1,
    icon: "",
    url: "/marketing/overview",
    title: "Overview",
    category: "marketing"
  },
  {
    id: 2,
    icon: "",
    url: "/marketing/seo",
    title: "SEO",
    category: "marketing"
  },
  {
    id: 3,
    icon: "",
    url: "/marketing/seo",
    title: "SEO",
    category: "marketing"
  },
  {
    id: 4,
    icon: "",
    url: "/marketing/paid-advertisement",
    title: "Paid Advertisement",
    category: "marketing"
  },
  {
    id: 5,
    icon: "",
    url: "/marketing/social-media",
    title: "Social Media",
    category: "marketing"
  },
  {
    id: 5,
    icon: "",
    url: "/marketing/customer-reputation",
    title: "Customer Reputation",
    category: "marketing"
  },
  {
    id: 6,
    icon: "",
    url: "/operational/dashboard",
    title: "Dashboard",
    category: "operational"
  },
  {
    id: 7,
    icon: "",
    url: "/operational/daily-data-entry",
    title: "Daily Data Entry",
    category: "operational"
  },
  {
    id: 8,
    icon: "",
    url: "/roles-and-permissions/providers/",
    title: "Providers",
    category: "providers"
  },
  {
    id: 9,
    icon: "",
    url: "/roles-and-permissions/roles/",
    title: "Roles",
    category: "providers"
  },
  {
    id: 10,
    icon: "",
    url: "/account-settings/account/",
    title: "Account",
    category: "account"
  },
  {
    id: 11,
    icon: "",
    url: "/account-settings/company/",
    title: "Company",
    category: "account"
  },
  {
    id: 12,
    icon: "",
    url: "/account-settings/security/",
    title: "Security",
    category: "account"
  },
  {
    id: 13,
    icon: "",
    url: "/account-settings/notifications/",
    title: "Notifications",
    category: "account"
  },
  {
    id: 14,
    icon: "",
    url: "/account-settings/connections/",
    title: "Connections",
    category: "account"
  }
];

// ** GET Search Data
mock.onGet("/app-bar/search").reply(config => {
  const { q = "" } = config.params;
  const queryLowered = q.toLowerCase();

  const exactData = {
    marketing: [],
    operational: [],
    providers: [],
    account: []
  };

  const includeData = {
    marketing: [],
    operational: [],
    providers: [],
    account: []
  };
  searchData.forEach(obj => {
    const isMatched = obj.title.toLowerCase().startsWith(queryLowered);
    if (isMatched && exactData[obj.category].length < 5) {
      exactData[obj.category].push(obj);
    }
  });
  searchData.forEach(obj => {
    const isMatched =
      !obj.title.toLowerCase().startsWith(queryLowered) && obj.title.toLowerCase().includes(queryLowered);
    if (isMatched && includeData[obj.category].length < 5) {
      includeData[obj.category].push(obj);
    }
  });
  const categoriesCheck = [];
  Object.keys(exactData).forEach(category => {
    if (exactData[category].length > 0) {
      categoriesCheck.push(category);
    }
  });
  if (categoriesCheck.length === 0) {
    Object.keys(includeData).forEach(category => {
      if (includeData[category].length > 0) {
        categoriesCheck.push(category);
      }
    });
  }
  const resultsLength = categoriesCheck.length === 1 ? 5 : 3;

  return [
    200,
    [
      ...exactData.marketing.concat(includeData.marketing).slice(0, resultsLength),
      ...exactData.operational.concat(includeData.operational).slice(0, resultsLength),
      ...exactData.providers.concat(includeData.providers).slice(0, resultsLength),
      ...exactData.account.concat(includeData.account).slice(0, resultsLength)
    ]
  ];
});
