import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const accessToken = process.env.NEXT_PUBLIC_MAP_BOX_API_KEY;

export const getMapCoordinatesByCity = createAsyncThunk(
  "appMapBox/getMapCoordinates",
  async (city, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          city
        )}.json?access_token=${accessToken}`,
        {
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      );

      if (response?.data?.features?.length) {
        return response?.data?.features[0]?.center;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getVisitorsMapData = createAsyncThunk(
  "appMapBox/getVisitorsMapData",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const action = await dispatch(getMapCoordinatesByCity(data?.city));

      return {
        city: data?.city,
        visitors: data?.visitors,
        diff: data?.diff,
        center: action.payload
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const appMapBoxSlice = createSlice({
  name: "appMapBox",
  initialState: {
    coordinates: {},
    visitorsMapData: [],
    isLoading: true,
    status: null,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVisitorsMapData.fulfilled, (state, action) => {
        state.visitorsMapData = state.visitorsMapData.concat(action.payload);
        state.isLoading = false;
        state.status = "success";
      })
      .addCase(getVisitorsMapData.pending, state => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(getVisitorsMapData.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  }
});

export default appMapBoxSlice.reducer;
