import { COMPANY_NOT_SELECTED, WEBSITE_NOT_SELECTED } from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { default as advertismentService } from "./service";

export const fetchGoogleAdvertisers = createAsyncThunk(
  "appAdvertisment/fetchGoogleAdvertisers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await advertismentService.fetchGoogleAdvertisers();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectGoogleAdvertiser = createAsyncThunk(
  "appAdvertisment/selectGoogleAdvertiser",
  async ({ advertiserId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await advertismentService.selectGoogleAdvertiser(advertiserId);
      if (response?.data?.advertiserId) {
        dispatch(fetchGoogleAdvertisers());
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFacebookAdvertisers = createAsyncThunk(
  "appAdvertisment/fetchFacebookAdvertisers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await advertismentService.fetchFacebookAdvertisers();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectFacebookAdvertiser = createAsyncThunk(
  "appAdvertisment/selectFacebookAdvertiser",
  async ({ advertiserId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await advertismentService.selectFacebookAdvertiser(advertiserId);
      if (response?.data?.advertiserId) {
        dispatch(fetchFacebookAdvertisers());
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAdsData = createAsyncThunk(
  "appAdvertisment/fetchAdsData",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment(end).utc().format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await advertismentService.fetchAdsData(startDate, endDate);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === WEBSITE_NOT_SELECTED) {
        await dispatch(fetchGoogleAdvertisers());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// export const fetchKeywords = createAsyncThunk(
//   'appAdvertisment/fetchKeywords',
//   async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch }) => {
//     try {
//       const { start, end } = date;
//       const startDate = start ? `&startDate=${start}` : '';
//       const endDate = end ? `&endDate=${end}` : start ? `&endDate=${start}` : '';

//       if (start || end) {
//         const response = await advertismentService.fetchKeywords(startDate, endDate);
//         return response.data;
//       }
//     } catch (error) {
//       if (error.response.data.message === COMPANY_NOT_SELECTED) {
//         window.location.replace('/select-company/');
//       }

//       if (error.response.data.message === WEBSITE_NOT_SELECTED) {
//         await dispatch(fetchGoogleAdvertisers());
//       } else {
//         return rejectWithValue(error);
//       }
//     }
//   }
// );
