// ** Mock Adapter
import mock from "src/@fake-db/mock";

const data = {
  permissions: [
    {
      id: 1,
      name: "Jamal Kerrod",
      goal: 4500,
      speciality: "General Dentist",
      createdDate: "29-09-2022",
      status: "active"
    },
    {
      id: 2,
      goal: 3500,
      name: "Shamus Tuttle",
      speciality: "Hygienist",
      createdDate: "29-09-2022",
      status: "active"
    },
    {
      id: 3,
      goal: 3000,
      name: "Devonne Wallbridge",
      createdDate: "29-09-2022",
      speciality: "General Dentist",
      status: "active"
    },
    {
      id: 4,
      goal: 4500,
      name: "Ariella Filippyev",
      createdDate: "29-09-2022",
      speciality: "General Dentist",
      status: "inactive"
    },
    {
      id: 5,
      goal: 4200,
      name: "Lutero Aloshechkin",
      createdDate: "29-09-2022",
      speciality: "Hygienist",
      status: "active"
    },
    {
      id: 6,
      goal: 4200,
      name: "Raynell Clendennen",
      createdDate: "29-09-2022",
      speciality: "General Dentist",
      status: "active"
    },
    {
      id: 7,
      goal: 4500,
      name: "Lorine Hischke",
      createdDate: "29-09-2022",
      speciality: "Hygienist",
      status: "inactive"
    }
  ]
};

// ------------------------------------------------
// GET: Return Permissions List
// ------------------------------------------------
mock.onGet("/apps/permissions/data").reply(config => {
  const { q = "" } = config.params;
  const queryLowered = q.toLowerCase();
  const filteredData = data.permissions.filter(
    permissions =>
      permissions.name.toLowerCase().includes(queryLowered) ||
      permissions.createdDate.toLowerCase().includes(queryLowered) ||
      permissions.assignedTo.some(i => i.toLowerCase().startsWith(queryLowered))
  );

  return [
    200,
    {
      params: config.params,
      allData: data.permissions,
      permissions: filteredData,
      total: filteredData.length
    }
  ];
});
