// ** Icon Imports
import Icon from "@core/components/icon";

// ** Third Party Import
import { useTranslation } from "react-i18next";

// ** Custom Components Imports
import OptionsMenu from "@core/components/option-menu";

const LanguageDropdown = ({ settings, saveSettings }) => {
  // ** Hook
  const { i18n } = useTranslation();

  const handleLangItemClick = lang => {
    i18n.changeLanguage(lang);
  };

  return (
    <OptionsMenu
      iconButtonProps={{ color: "inherit" }}
      icon={<Icon fontSize='1.5rem' icon='tabler:language' />}
      menuProps={{ sx: { "& .MuiMenu-paper": { mt: 4.5, minWidth: 130 } } }}
      options={[
        {
          text: "English",
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === "en",
            onClick: () => {
              handleLangItemClick("en");
              saveSettings({ ...settings, direction: "ltr" });
            }
          }
        },
        {
          text: "French",
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === "fr",
            onClick: () => {
              handleLangItemClick("fr");
              saveSettings({ ...settings, direction: "ltr" });
            }
          }
        },
        {
          text: "Arabic",
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === "ar",
            onClick: () => {
              handleLangItemClick("ar");
              saveSettings({ ...settings, direction: "rtl" });
            }
          }
        }
      ]}
    />
  );
};

export default LanguageDropdown;
