export const defaultSuggestionsData = [
  {
    category: "Marketing",
    suggestions: [
      {
        suggestion: "Overview",
        link: "/marketing/overview"
      },
      {
        suggestion: "SEO",
        link: "/marketing/seo"
      },
      {
        suggestion: "Paid Advertisement",
        link: "/marketing/paid-advertisement"
      },
      {
        suggestion: "Social Media",
        link: "/marketing/social-media"
      },
      {
        suggestion: "Customer Reputation",
        link: "/marketing/customer-reputation"
      }
    ]
  },
  {
    category: "Operational",
    suggestions: [
      {
        suggestion: "Dashboard",
        link: "/operational/dashboard"
      },
      {
        suggestion: "Daily Data Entry",
        link: "/operational/daily-data-entry"
      }
    ]
  },
  {
    category: "Providers & Roles",
    suggestions: [
      {
        suggestion: "Providers",
        link: "/roles-and-permissions/providers/"
      },
      {
        suggestion: "Roles",
        link: "/roles-and-permissions/roles/"
      }
    ]
  },
  {
    category: "Account Settings",
    suggestions: [
      {
        suggestion: "Account",
        link: "/account-settings/account/"
      },
      {
        suggestion: "Company",
        link: "/account-settings/company/"
      },
      {
        suggestion: "Security",
        link: "/account-settings/security/"
      },
      {
        suggestion: "Notifications",
        link: "/account-settings/notifications/"
      },
      {
        suggestion: "Connections",
        link: "/account-settings/connections/"
      }
    ]
  }
];
