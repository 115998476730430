export const providersFormLabel = {
  fontWeight: 450,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#4B465C",
  margin: 0,
  marginBottom: "4px"
};

export const providersFormTitle = {
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "22px"
};

export const checkBoxLabel = {
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "22px"
};

export const checkBoxesDays = {
  "& .MuiSwitch-track": {
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #4B465C",
    border: "none"
  },
  "& .MuiSwitch-thumb": {
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #4B465C"
  },
  "& .Mui-checked+.MuiSwitch-track": {
    background: "#557AFF"
  },
  "& .Mui-checked > .MuiSwitch-thumb": {
    background: "#FFFFFF"
  }
};

export const checkBoxes = {
  "& .MuiSwitch-thumb": {
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #4B465C"
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    background: "#FFFFFF"
  }
};

export const buttonStyles = {
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "19px"
};

export const buttonSecondaryStyles = {
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "19px",
  background: "rgba(168, 170, 174, 0.16)",
  color: "#A8AAAE",
  "&:hover": {
    color: "#ffffff"
  }
};

export const dataGridStyles = {
  "& .MuiDataGrid-columnHeader": {
    background: "#FFFFFF",
    borderTop: "1px solid #DBDADE"
  },
  "& .MuiAvatar-root ": {
    fontWeight: 450,
    fontSize: "15px",
    lineHeight: "22px"
  },
  "& .MuiChip-root": {
    fontWeight: 400
  },
  "& .MuiChip-colorSecondary": {
    color: "#A8AAAE"
  }
};

export const paginationStyles = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "30px 20px",
  flexWrap: "wrap"
};

export const buttonPaginationStyles = {
  backgroundColor: "rgba(75, 70, 92, 0.08)",
  fontWeight: 450,
  fontSize: "15px",
  lineHeight: "22px",
  height: "34px",
  color: "rgba(75, 70, 92, 0.8)",
  textTransform: "capitalize"
};
