import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import caseManagementService from "./service";
import moment from "moment";

export const fetchAllCases = createAsyncThunk(
  "appCaseManagement/fetchAllCases",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment(end).utc().format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await caseManagementService.fetchAllCases(startDate, endDate);
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCase = createAsyncThunk("appCaseManagement/createCase", async (data, { rejectWithValue }) => {
  try {
    const payload = {
      title: data?.caseTitle,
      description: data?.caseDescription,
      origin: data?.caseOrigin,
      priority: data?.casePriority
    };
    const response = await caseManagementService.createCase(payload);
    toast.success("Case Created Successfully");
    return response.data;
  } catch (error) {
    toast.error(error.message);
    return rejectWithValue(error);
  }
});

export const resetCasesCache = createAsyncThunk(
  "appCaseManagement/resetCasesCache",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await caseManagementService.resetCasesCache();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeDynamicsAccess = createAsyncThunk(
  "appCaseManagement/removeDynamicsAccess",
  async ({ date }, { rejectWithValue, dispatch }) => {
    try {
      const response = await caseManagementService.removeDynamicsAccess();
      dispatch(fetchAllCases({ date }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestDynamicsAccess = createAsyncThunk(
  "appCaseManagement/requestDynamicsAccess",
  async ({ date }, { rejectWithValue, dispatch }) => {
    try {
      const response = await caseManagementService.requestDynamicsAccess();
      dispatch(fetchAllCases({ date }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
