export const getPermissionURL = permissions => {
  const sortOrder = [
    "Marketing Dashboard",
    "SEO",
    "Customer Reputation",
    "Paid Advertisement",
    "Operational Dashboard",
    "Operational Data Entry",
    "Executive Report",
    "User Management",
    "Social Media",
    "Account Settings",
    "Cases"
  ];

  const sortedPermissions = [...permissions].sort((a, b) => {
    return sortOrder.indexOf(a.permissionName) - sortOrder.indexOf(b.permissionName);
  });

  const permission = sortedPermissions.find(permission => permission.read === true);

  switch (permission?.permissionName) {
    case "Marketing Dashboard":
      return "/marketing/overview";
    case "SEO":
      return "/marketing/seo";
    case "Customer Reputation":
      return "/marketing/customer-reputation";
    case "Paid Advertisement":
      return "/marketing/paid-advertisement";
    case "Operational Data Entry":
      return "/operational/daily-data-entry";
    case "Operational Dashboard":
      return "/operational/dashboard";
    case "User Management":
      return "/roles-and-permissions/providers";
    case "Social Media":
      return "/social-media";
    case "Account Settings":
      return "/account-settings/account";
    case "Executive Report":
      return "/reports/operational/";
    case "Cases":
      return "/cases/all-cases";
    default:
      return "/";
  }
};
