// import { AbilityContext } from '../../layouts/components/acl/Can';
// import { useContext } from 'react';

const navigation = () => {
  // const ability = useContext(AbilityContext);

  return [
    {
      title: "Marketing",
      icon: "tabler:presentation",
      // badgeContent: 'new',
      // badgeColor: 'error',
      children: [
        {
          title: "Overview",
          path: "/marketing/overview",
          action: "read",
          subject: "Marketing Dashboard"
        },
        {
          title: "SEO",
          path: "/marketing/seo",
          action: "read",
          subject: "SEO"
        },
        {
          title: "Paid Advertisement",
          path: "/marketing/paid-advertisement",
          action: "read",
          subject: "Paid Advertisement"
        },
        {
          title: "Social Media",
          path: "/marketing/social-media",
          action: "read",
          subject: "Social Media"
        },
        {
          title: "Customer Reputation",
          path: "/marketing/customer-reputation",
          action: "read",
          subject: "Customer Reputation"
        }

        // {
        //   title: 'Analytics',
        //   path: '/dashboards/analytics'
        // },
        // {
        //   title: 'CRM',
        //   path: '/dashboards/crm'
        // },
        // {
        //   title: 'eCommerce',
        //   path: '/dashboards/ecommerce'
        // }
      ]
    },
    {
      title: "Operational",
      icon: "tabler:briefcase",
      // badgeContent: 'new',
      // badgeColor: 'error',
      children: [
        {
          action: "read",
          subject: "Operational Dashboard",
          title: "Dashboard",
          path: "/operational/dashboard"
        },
        {
          action: "read",
          subject: "Operational Data Entry",
          title: "Daily Data Entry",
          path: "/operational/daily-data-entry"
        }
      ]
    },
    {
      action: "read",
      subject: "User Management",
      sectionTitle: "USER REPORTS"
    },
    {
      title: "Reports",
      icon: "tabler:files",
      children: [
        {
          action: "read",
          subject: "User Management",
          title: "Operational Report",
          path: "/reports/operational"
        }
      ]
    },
    {
      action: "read",
      subject: "User Management",
      sectionTitle: "PROVIDERS & ROLES"
    },
    {
      title: "Providers & Roles",
      icon: "tabler:settings",
      children: [
        {
          action: "read",
          subject: "User Management",
          title: "Users",
          path: "/roles-and-permissions/users"
        },
        {
          action: "read",
          subject: "User Management",
          title: "Roles",
          path: "/roles-and-permissions/roles"
        },
        {
          action: "read",
          subject: "User Management",
          title: "Providers",
          path: "/roles-and-permissions/providers"
        }
      ]
    },
    {
      action: "read",
      subject: "Cases",
      sectionTitle: "Cases"
    },
    {
      title: "Cases",
      icon: "tabler:stack-2",
      subject: "Cases",
      children: [
        {
          action: "read",
          subject: "Cases",
          title: "All Cases",
          path: "/cases/all-cases"
        },
        {
          action: "read",
          subject: "Cases",
          title: "Compose",
          path: "/cases/compose"
        }
      ]
    },
    {
      action: "read",
      subject: "acl-page",
      sectionTitle: "Settings"
    },
    {
      title: "Account Settings",
      icon: "tabler:user",
      action: "read",
      subject: "acl-page",
      children: [
        {
          action: "read",
          subject: "acl-page",
          title: "Account",
          path: "/account-settings/account"
        },
        {
          action: "read",
          subject: "Account Settings",
          title: "Company",
          path: "/account-settings/company"
        },

        {
          action: "read",
          subject: "acl-page",
          title: "Security",
          path: "/account-settings/security"
        },
        {
          action: "read",
          subject: "acl-page",
          title: "Notifications",
          path: "/account-settings/notifications"
        },
        {
          action: "read",
          subject: "Account Settings",
          title: "Connections",
          path: "/account-settings/connections"
        }
      ]
    }
  ];
};

export default navigation;
