import { Box, Typography } from "@mui/material";
import Image from "next/image";
import happy from "../../public/images/icons/happy.svg";
// import promo from '../../public/images/icons/promo.svg';
import thumbsUp from "../../public/images/icons/thumbsUp.svg";
import Icon from "@core/components/icon";
import { SORT_TYPES } from "../constants/sort-by-constants";
import { DIFF_UNKNOWN } from "../constants/types-constants";
import Confused from "../icons/Confused";
import Excited from "../icons/Excited";
import Sad from "../icons/Sad";
import { generateCityAcronym } from "./city-helper";

export const getSeoRows = data => {
  return data?.map(item => {
    const city = generateCityAcronym(item.name);
    const visitors = item.value;
    const diff = item.diff === DIFF_UNKNOWN ? "" : item.diff;
    return { city, visitors, diff };
  });
};

export const getKeywordRows = data => {
  return data?.map((item, index) => {
    const name = index + 1;
    const keyword = item.name;
    const click = item.value;
    const diff = item.diff;

    return { name, keyword, click, diff };
  });
};

export const convertCompetitorsDate = value => {
  const year = parseInt(value.substr(0, 4), 10);
  const month = parseInt(value.substr(5, 2), 10);

  // Create a Date object
  const date = new Date(year, month - 1); // Subtract 1 from month to create a date with the correct month

  // Format the date as "MM/YYYY" (e.g., "01/2009")
  const formattedMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const formattedYear = date.getFullYear();

  return `${formattedMonth}/${formattedYear}`;
};

export const getTickAmount = filterText => {
  switch (filterText) {
    case SORT_TYPES.LAST_MONTHS:
      return 4;
    case SORT_TYPES.LAST_WEEK:
      return 6;
    case SORT_TYPES.LAST_THREE_MONTHS:
      return 6;
    case SORT_TYPES.LAST_SIX_MONTHS:
      return 8;
    case SORT_TYPES.LAST_YEAR:
      return 11;
    default:
      return 0;
  }
};

export const divideArrayWithGap = (originalArray, totalDates = 30) => {
  if (originalArray?.length) {
    if (originalArray?.length <= totalDates) {
      return originalArray;
    }

    const numberOfParts = Math.min(originalArray?.length, totalDates);

    if (numberOfParts <= 1) {
      return originalArray;
    }

    const selectedDates = [];
    const gapSize = Math.floor((originalArray?.length - 2) / (numberOfParts - 2));

    // Include the first value
    selectedDates.push(originalArray[0]);

    for (let i = 1; i < numberOfParts - 1; i++) {
      const index = 1 + i * gapSize;
      if (index < originalArray?.length - 1) {
        selectedDates.push(originalArray[index]);
      } else {
        break; // If the index is out of bounds, break the loop
      }
    }

    // Include the last value
    selectedDates.push(originalArray[originalArray?.length - 1]);

    return selectedDates;
  }

  return originalArray;
};

export const getSeoCity = rows => {
  return rows?.map(item => {
    return item?.city;
  });
};

export const getSeoVisitors = rows => {
  return rows?.map(item => {
    return item?.visitors;
  });
};

export const getColor = value => {
  if (value === "") {
    return "inherit";
  } else {
    return value?.replace("%", "") > 0 ? "#10B981" : "#EA5455";
  }
};

export const getPercentageColor = value => {
  if (value && value?.startsWith("+")) {
    return "#10B981";
  } else {
    return "#EA5455";
  }
};

export const getSurplusColor = value => {
  if (value === 0 || !value) {
    return "inherit";
  } else {
    return value < 0 ? "#EA5455" : "#10B981";
  }
};

export const getSpeedColor = value => {
  if (value <= 49) {
    return "#EA5455";
  } else if (value <= 79) {
    return "#FF9F43";
  } else {
    return "#10B981";
  }
};

export const numberWithCommas = number => {
  return number.toLocaleString("en-US", { minimumFractionDigits: 2 });
};

export const getArrowIcon = value => {
  if (value === "" || value === "unknown" || value === 0 || !value) {
    return;
  }
  return value?.replace("%", "") > 0 ? "tabler:chevron-up" : "tabler:chevron-down";
};

export const getSeoProgressColor = value => {
  if (value <= 49) {
    return "error";
  } else if (value <= 79) {
    return "warning";
  } else {
    return "success";
  }
};

export const getAdsArrowIcon = value => {
  if (value.startsWith("+")) {
    return "tabler:chevron-up";
  } else {
    return "tabler:chevron-down";
  }
};

export const getEmoji = value => {
  if (value <= 49) {
    return <Sad />;
  } else if (value <= 79) {
    return <Confused />;
  } else {
    return <Excited />;
  }
};

export const getSeoTotalColor = (value, theme) => {
  if (value <= 49) {
    return [theme.palette.error.main];
  } else if (value <= 79) {
    return [theme.palette.warning.main];
  } else {
    return [theme.palette.success.main];
  }
};

export const getSeoPerformanceIcon = (value, theme) => {
  if (value <= 49) {
    return (
      <Box
        sx={{
          background: "#ED6C6829",
          height: "26px",
          maxWidth: "26px",
          width: "100%",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Icon color={theme?.palette?.error?.main} fontSize='1.2rem' icon='tabler:alert-triangle' />
      </Box>
    );
  } else if (value <= 79) {
    return (
      <Box
        sx={{
          background: "#FF9F4329",
          height: "26px",
          maxWidth: "26px",
          width: "100%",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Icon color={theme?.palette?.warning?.main} fontSize='1.2rem' icon='tabler:alert-triangle' />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          background: "#28C76F29",
          height: "26px",
          maxWidth: "26px",
          width: "100%",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Icon color={theme?.palette?.success?.main} fontSize='1.2rem' icon='mdi:check' />
      </Box>
    );
  }
};

export function addKIfOver1000(value) {
  if (value >= 1000) {
    return (value / 1000)?.toFixed(1) + "K";
  } else {
    return value?.toString();
  }
}

export const getSEOTitle = value => {
  if (value <= 49) {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            "@media (max-width:820px)": {
              textAlign: "center"
            },
            color: "rgba(75, 70, 92, 1)",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "24px"
          }}
        >
          Don't worry <Image width={24} height={24} src={happy} alt='' /> , there's room for improvement!
        </Typography>

        <Typography
          sx={{
            "@media (max-width:820px)": {
              textAlign: "center"
            },
            color: "rgba(75, 70, 92, .5)",
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "20px"
          }}
        >
          Focus on optimizing your content, meta tags, and technical aspects to improve your website's visibility and
          online presence.
        </Typography>
      </Box>
    );
  } else if (value <= 89) {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            "@media (max-width:820px)": {
              textAlign: "center"
            },
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "24px"
          }}
        >
          Good job! <Image width={24} height={24} src={thumbsUp} alt='' /> Your website is optimized to a satisfactory
          level
        </Typography>

        <Typography
          sx={{
            "@media (max-width:820px)": {
              textAlign: "center"
            },
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "20px"
          }}
        >
          Explore optimization opportunities to enhance your search engine visibility and attract more potential
          patients.
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            "@media (max-width:820px)": {
              textAlign: "center"
            },
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "24px"
          }}
        >
          Congratulations! 🎉 Your website has <br /> achieved a high level of optimization
        </Typography>

        <Typography
          sx={{
            "@media (max-width:820px)": {
              textAlign: "center"
            },
            color: "rgba(75, 70, 92, .5)",
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "20px"
          }}
        >
          Keep up the good work and continue optimizing to maintain your excellent performance.
        </Typography>
      </Box>
    );
  }
};

export const getSeoText = value => {
  if (value <= 49) {
    return (
      "Your SEO score indicates room for improvement. Focus on optimizing your website, " +
      "targeting relevant keywords, and building quality backlinks to boost your organic visibility."
    );
  } else if (value <= 79) {
    return (
      "Great job! Your SEO score is in a good range, but there's still potential for further improvement. " +
      "Continue refining your optimization strategies, creating valuable content, and monitoring your keyword performance."
    );
  } else {
    return (
      "Your business is excelling and attracting more " +
      "patients to your website. Keep up the great work, " +
      "and let us help you maintain your leading position!"
    );
  }
};

export const VISITORS_COLUMNS = [
  {
    id: "city",
    label: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            padding: "4px",
            background: "rgba(134, 146, 208, 0.08)",
            width: "26px",
            height: "26px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginRight: "16px"
          }}
        >
          <Icon color='rgba(43, 89, 255, 1)' fontSize='1.25rem' icon='mdi:map-marker-outline' />
        </Box>
        City
      </Box>
    ),
    minWidth: 100,
    align: "left"
  },
  {
    id: "visitors",
    label: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            padding: "4px",
            background: "rgba(134, 146, 208, 0.08)",
            width: "26px",
            height: "26px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginRight: "16px"
          }}
        >
          <Icon color='rgba(43, 89, 255, 1)' fontSize='1.25rem' icon='tabler:users' />{" "}
        </Box>
        Visitors
      </Box>
    ),
    minWidth: 100,
    align: "left",
    format: value => value.toLocaleString("en-US")
  }
];
