// slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  replies: []
};

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    //initial AI Generated replies
    setReplies: (state, action) => {
      state.replies = action.payload;
    },
    //for updating a particular reply after triggering regenration
    updateReply: (state, action) => {
      const updatedReply = action.payload;
      const index = state.replies.findIndex(reply => reply.id === updatedReply.id);
      if (index !== -1) {
        state.replies[index] = updatedReply;
      }
    }
  }
});

export const { setReplies, updateReply } = reviewSlice.actions;
export default reviewSlice.reducer;
