const Sad = () => {
  return (
    <svg width='65' height='65' viewBox='0 0 65 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M62.25 32.7598C62.25 49.3283 48.8185 62.7598 32.25 62.7598C15.6815 62.7598 2.25 49.3283 2.25 32.7598C2.25 16.1912 15.6815 2.75977 32.25 2.75977C48.8185 2.75977 62.25 16.1912 62.25 32.7598Z'
        fill='#EA5455'
        fillOpacity='0.16'
        stroke='#EA5455'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.2496 26.0934H22.2829'
        stroke='#EA5455'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.2496 26.0934H42.2829'
        stroke='#EA5455'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.918 43.5925C26.1123 41.3529 29.1158 40.0908 32.2513 40.0908C35.3868 40.0908 38.3903 41.3529 40.5846 43.5925'
        stroke='#EA5455'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Sad;
