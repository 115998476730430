import { getFilterDays } from "../helpers/date-helper";

export const SORT_DATA = [
  {
    type: "Week",
    title: "Week",
    text: "Last week",
    days: 7
  },
  {
    type: "Last30days",
    title: "Last 30 days",
    text: "Last month",
    days: 30
  },
  {
    type: "3months",
    title: "Last 3 months",
    text: "Last 3 months",
    days: getFilterDays(3)
  },
  {
    type: "Last6Months",
    title: "Last 6 months",
    text: "Last 6 months",
    days: 182
  },
  {
    type: "Last12Months",
    title: "Last 12 months",
    text: "Last 12 months",
    days: getFilterDays(12, true)
  }
];

export const SORT_DATA_SOCIAL_MEDIA = [
  {
    type: "Week",
    title: "Week",
    text: "Last week",
    days: 7
  },
  {
    type: "Last30days",
    title: "Last 30 days",
    text: "Last month",
    days: 30
  },
  {
    type: "3months",
    title: "Last 3 months",
    text: "Last 3 months",
    days: getFilterDays(3)
  },
  {
    type: "Last6Months",
    title: "Last 6 months",
    text: "Last 6 months",
    days: 182
  },
  {
    type: "Last12Months",
    title: "Last 12 months",
    text: "Last 12 months",
    days: getFilterDays(12, true)
  }
];

export const REPORT_SORT_DATA = [
  {
    type: "Weekly",
    title: "Weekly",
    text: "Weekly",
    days: 7
  },
  {
    type: "Monthly",
    title: "Monthly",
    text: "Monthly",
    days: 30
  },
  {
    type: "Quarterly",
    title: "Quarterly",
    text: "Quarterly",
    days: getFilterDays(3)
  },
  {
    type: "Yearly",
    title: "Yearly",
    text: "Yearly",
    days: 182
  },
  {
    type: "Custom",
    title: "Custom",
    text: "Custom",
    days: getFilterDays(12)
  }
];

export const SORT_TYPES = {
  LAST_MONTHS: "Last month",
  LAST_WEEK: "Last week",
  LAST_YEAR: "Last 12 months",
  LAST_SIX_MONTHS: "Last 6 months",
  LAST_THREE_MONTHS: "Last 3 months"
};

export const REPORTING_SORT_TYPES = {
  MONTHLY: "monthly",
  WEEKLY: "weekly",
  YEARLY: "yearly",
  QUARTERLY: "quarterly",
  CUSTOM: "custom"
};

export const PREVIOUS_PERIOD = "than previous period";
