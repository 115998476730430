import toast from "react-hot-toast";
import {
  PAGE_NOT_FOUND,
  LOCATION_NOT_FOUND,
  PROPERTY_NOT_FOUND,
  SOCIAL_NOT_SELECTED,
  COMPANY_NOT_SELECTED,
  LOCATION_NOT_SELECTED,
  PASSWORD_NOT_SELECTED,
  YOU_DO_NOT_WORK_AT_THIS_COMPANY,
  NOT_LOGGED_IN,
  FACEBOOK_TOKEN_EXPIRED
} from "../constants/response-messages";

export const defaultExcludedMessages = [
  LOCATION_NOT_SELECTED,
  PASSWORD_NOT_SELECTED,
  COMPANY_NOT_SELECTED,
  SOCIAL_NOT_SELECTED,
  LOCATION_NOT_FOUND,
  PROPERTY_NOT_FOUND,
  PAGE_NOT_FOUND
];

export const handleError = (message, statusCode, excludedMessages = defaultExcludedMessages) => {
  // Handle specific redirects
  if (
    (message === COMPANY_NOT_SELECTED || message === YOU_DO_NOT_WORK_AT_THIS_COMPANY) &&
    window.location.pathname !== "/select-company/"
  ) {
    window.location.replace("/select-company");
    return;
  }

  if (message === FACEBOOK_TOKEN_EXPIRED && window.location.pathname !== "/account-settings/connections") {
    window.location.replace("/account-settings/connections");
    return;
  }

  // Redirect to login page on 401 status
  if (statusCode === 401 && message === NOT_LOGGED_IN) {
    window.location.replace("/login");
    return;
  }

  // Display error toast for server errors or if the message is not excluded
  if (statusCode === 500 || (message && !excludedMessages.includes(message))) {
    toast.error(message || "Something went wrong.", { duration: 2000 });
  }
};

export const openCenteredWindow = (url, name, width, height) => {
  // Ensure the window doesn't open off-screen
  const left = Math.max(0, window.screen.width / 2 - width / 2);
  const top = Math.max(0, window.screen.height / 2 - height / 2);

  window.open(url, name, `width=${width},height=${height},left=${left},top=${top}`);
};
