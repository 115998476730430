import { analyticsPath } from "@constants/base-urls";
import { COMPANY_NOT_SELECTED, SOCIAL_NOT_SELECTED } from "@constants/response-messages";
import { handleError } from "@helpers/store-helper";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getListMyPages } from "../auth";

export const fetchWeeklyReport = createAsyncThunk(
  "appAnalytics/fetchReport",
  async ({ date }, { rejectWithValue, dispatch }) => {
    try {
      if (date) {
        const response = await axios.get(`${analyticsPath}/operationals/reports/weekly?date=${date}`, {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        });

        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchMonthlyReport = createAsyncThunk(
  "appAnalytics/fetchReport",
  async ({ month, year }, { rejectWithValue, dispatch }) => {
    try {
      if (month && year) {
        const response = await axios.get(`${analyticsPath}/operationals/reports/monthly?year=${year}&month=${month}`, {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        });

        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchQuarterlyReport = createAsyncThunk(
  "appAnalytics/fetchReport",
  async ({ quarter, year }, { rejectWithValue, dispatch }) => {
    try {
      if (quarter && year) {
        const response = await axios.get(
          `${analyticsPath}/operationals/reports/quarterly?year=${year}&quarter=${quarter}`,
          {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          }
        );

        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchYearlyReport = createAsyncThunk(
  "appAnalytics/fetchReport",
  async ({ year }, { rejectWithValue, dispatch }) => {
    try {
      if (year) {
        const response = await axios.get(`${analyticsPath}/operationals/reports/yearly?year=${year}`, {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        });

        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchCustomReport = createAsyncThunk(
  "appAnalytics/fetchReport",
  async (reportCustomDates, { rejectWithValue, dispatch }) => {
    const { currentStartDate, currentEndDate, previousStartDate, previousEndDate } = reportCustomDates;

    try {
      if (reportCustomDates) {
        const response = await axios.get(
          `${analyticsPath}/operationals/reports/custom?currentStartDate=${currentStartDate}&currentEndDate=${currentEndDate}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`,
          {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          }
        );

        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === SOCIAL_NOT_SELECTED) {
        await dispatch(getListMyPages());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const setReports = (state, action) => {
  state.errorMessage = "";
  state.isReportLoading = false;
  state.isFiltersReportLoading = false;

  state.operationalReport = action?.payload;
};

const setError = (state, action) => {
  state.isReportLoading = false;
  state.isFiltersReportLoading = false;

  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.errorMessage = message;

  handleError(message, statusCode);
};

export const reporting = createSlice({
  name: "appSocialMedia",
  initialState: {
    errorMessage: "",
    isReportLoading: false,
    isFiltersReportLoading: false,
    operationalReport: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMonthlyReport.pending, (state, action) => {
        state.errorMessage = "";
        state.isReportLoading = true;
        state.isFiltersReportLoading = true;
      })
      .addCase(fetchMonthlyReport.fulfilled, setReports)
      .addCase(fetchMonthlyReport.rejected, setError);
    // .addCase(fetchQuarterlyReport.pending, (state, action) => {
    //   state.errorMessage = ''
    //   state.isReportLoading = true
    //   state.isFiltersReportLoading = true
    // })
    // .addCase(fetchQuarterlyReport.fulfilled, setReports)
    // .addCase(fetchQuarterlyReport.rejected, setError)
    // .addCase(fetchYearlyReport.pending, (state, action) => {
    //   state.errorMessage = ''
    //   state.isReportLoading = true
    //   state.isFiltersReportLoading = true
    // })
    // .addCase(fetchYearlyReport.fulfilled, setReports)
    // .addCase(fetchYearlyReport.rejected, setError)
  }
});

export default reporting.reducer;
