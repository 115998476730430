// ** React Imports
import { useState, Fragment } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

// ** Icon Imports
import Icon from "@core/components/icon";

// ** Context
import { useAuth } from "src/hooks/useAuth";
import toast from "react-hot-toast";

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  "&:hover .MuiBox-root, &:hover .MuiBox-root svg": {
    color: theme.palette.primary.main
  }
}));

const UserDropdown = props => {
  // ** Props
  const { settings, profile, role } = props;

  const { firstName, lastName, image } = profile;

  const fullName = firstName !== undefined && lastName !== undefined ? `${firstName} ${lastName}` : "";

  // ** States
  const [anchorEl, setAnchorEl] = useState(null);

  // ** Hooks
  const router = useRouter();
  const { logout } = useAuth();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = url => {
    if (url) {
      router.push(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    px: 4,
    py: 1.75,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      mr: 2.5,
      color: "text.primary"
    }
  };

  const handleLogout = () => {
    logout(error => {
      if (error) {
        toast.error("Something went wrong, please try again later", {
          duration: 2000
        });
      }
    });
    handleDropdownClose();
  };

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: "pointer" }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <Avatar alt={fullName} onClick={handleDropdownOpen} sx={{ width: 40, height: 40 }} src={image} />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, mt: 4.5 } }}
        anchorOrigin={{ vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }}
      >
        <Box sx={{ py: 1.75, px: 6 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <Avatar alt={fullName} src={image} sx={{ width: "2.5rem", height: "2.5rem" }} />
            </Badge>
            <Box sx={{ display: "flex", ml: 2.5, alignItems: "flex-start", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: 500, wordBreak: "break-word" }}>{fullName}</Typography>
              <Typography variant='body2'>{role}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
        <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose("/account-settings/account")}>
          <Box sx={styles}>
            <Icon icon='tabler:user-check' />
            My Profile
          </Box>
        </MenuItemStyled>
        {/* <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
          <Box sx={styles}>
            <Icon icon='tabler:mail' />
            Inbox
          </Box>
        </MenuItemStyled> */}
        {/* <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
          <Box sx={styles}>
            <Icon icon='tabler:message-2' />
            Chat
          </Box>
        </MenuItemStyled> */}
        <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
        {/* <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose('/pricing')}>
          <Box sx={styles}>
            <Icon icon='tabler:currency-dollar' />
            Pricing
          </Box>s
        </MenuItemStyled> */}
        {/* <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose('/faq')}>
          <Box sx={styles}>
            <Icon icon='tabler:help' />
            FAQ
          </Box>
        </MenuItemStyled> */}
        {/* <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} /> */}
        <MenuItemStyled
          onClick={handleLogout}
          sx={{
            py: 2,
            "& svg": { mr: 2, fontSize: "1.375rem" }
          }}
        >
          <Icon icon='tabler:logout' />
          Logout
        </MenuItemStyled>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
