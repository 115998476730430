import {
  formBoxStyles,
  formControlSecondStyles,
  formControlStyles,
  inputStyles
} from "@constants/data-entry-styles-constants";
import { buttonStyles, providersFormLabel } from "@constants/providers-styles-constants";
import { NAME_REGEX, PASSWORD_REGEX } from "@constants/regex";
import {
  FULL_NAME_REQUIRED,
  INVALID_PASSWORD,
  NOT_CONTAIN_NUMBERS,
  PASSWORD_LENGTH,
  PASSWORD_REQUIRED
} from "@constants/validation";
import Icon from "@core/components/icon";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";

const PasswordNotSelectedModal = props => {
  const { selectNewPassword } = useAuth();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: ""
  });

  const [values, setValues] = useState({
    showNewPassword: false,
    showConfirmNewPassword: false
  });

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
  };

  const handleChange = (field, value) => {
    setForm(prev => ({
      ...prev,
      [field]: value
    }));
  };
  const handleAdd = () => {
    props.handleClose();

    selectNewPassword(
      {
        firstName: form.firstName,
        lastName: form.lastName,
        password: form.password
      },
      error => {
        if (error) {
          toast.error("Something went wrong.", {
            duration: 2000
          });
        }
      }
    );
  };

  const schema = yup.object().shape({
    firstName: yup.string().required(FULL_NAME_REQUIRED).matches(NAME_REGEX, NOT_CONTAIN_NUMBERS),
    lastName: yup.string().required(FULL_NAME_REQUIRED).matches(NAME_REGEX, NOT_CONTAIN_NUMBERS),
    password: yup
      .string()
      .min(8, PASSWORD_LENGTH)
      .required(PASSWORD_REQUIRED)
      .matches(PASSWORD_REGEX, INVALID_PASSWORD),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match") // Validation rule to ensure match with 'password'
      .required("Please confirm your password")
  });

  const {
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      form
    },
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  const isDisabled = form.password !== form.confirmPassword;

  return (
    <Dialog maxWidth='sm' fullWidth onClose={props.handleClose} open={props.open}>
      <DialogTitle
        sx={{
          textAlign: "center",
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        <Typography variant='h5' component='span' sx={{ fontSize: "26px" }}>
          Reset Password
        </Typography>

        <Typography
          variant='body2'
          sx={{ marginTop: "12px", fontWeight: "450", fontSize: "15px", lineHeight: "22px", color: "#4B465C" }}
        >
          Enter new password
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
          pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
        }}
      >
        <Box
          component='form'
          sx={{
            mt: 4,
            mx: "auto",
            width: "100%",
            maxWidth: 490,
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Box sx={formBoxStyles}>
            <FormControl sx={formControlStyles}>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <>
                    <FormHelperText sx={providersFormLabel} id='providers-firstname'>
                      First Name
                    </FormHelperText>
                    <OutlinedInput
                      sx={inputStyles}
                      value={form.firstName}
                      id='providers-firstname'
                      onBlur={onBlur}
                      onChange={event => {
                        onChange(event);
                        handleChange("firstName", event.target.value);
                      }}
                      error={Boolean(errors.firstName)}
                      placeholder='Jamal'
                    />
                  </>
                )}
              />
              {errors.firstName && (
                <FormHelperText sx={{ color: "error.main" }}>{errors.firstName.message}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box sx={formBoxStyles}>
            <FormControl sx={{ ...formControlSecondStyles, ml: 0, mt: "16px" }}>
              <Controller
                name='lastName'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <>
                    <FormHelperText sx={providersFormLabel} id='providers-lastname'>
                      Last Name
                    </FormHelperText>
                    <OutlinedInput
                      sx={inputStyles}
                      value={form.lastName}
                      id='providers-lastname'
                      onBlur={onBlur}
                      onChange={event => {
                        onChange(event);
                        handleChange("lastName", event.target.value);
                      }}
                      error={Boolean(errors.lastName)}
                      placeholder='Kerrod'
                    />
                  </>
                )}
              />
              {errors.lastName && (
                <FormHelperText sx={{ color: "error.main" }}>{errors.lastName.message}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box sx={formBoxStyles}>
            <FormControl sx={{ ...formControlSecondStyles, ml: 0, mt: "16px" }}>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <>
                    <FormHelperText sx={providersFormLabel} id='providers-password'>
                      Password
                    </FormHelperText>
                    <OutlinedInput
                      sx={inputStyles}
                      value={form.password}
                      id='providers-password'
                      onBlur={onBlur}
                      onChange={event => {
                        onChange(event);
                        handleChange("password", event.target.value);
                      }}
                      error={Boolean(errors.password)}
                      placeholder='Enter password'
                      type={values.showNewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={handleClickShowNewPassword}
                          >
                            <Icon icon={values.showNewPassword ? "tabler:eye" : "tabler:eye-off"} fontSize={20} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </>
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: "error.main" }}>{errors.password.message}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box sx={formBoxStyles}>
            <FormControl sx={{ ...formControlSecondStyles, ml: 0, mt: "16px" }}>
              <Controller
                name='confirmPassword'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <>
                    <FormHelperText sx={providersFormLabel} id='providers-confirmPassword'>
                      Confirm Password
                    </FormHelperText>
                    <OutlinedInput
                      sx={inputStyles}
                      value={form.confirmPassword}
                      id='providers-confirmPassword'
                      onBlur={onBlur}
                      onChange={event => {
                        onChange(event);
                        handleChange("confirmPassword", event.target.value);
                      }}
                      error={Boolean(errors.confirmPassword)}
                      placeholder='Confirm password'
                      type={values.showConfirmNewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={handleClickShowConfirmNewPassword}
                          >
                            <Icon
                              icon={values.showConfirmNewPassword ? "tabler:eye" : "tabler:eye-off"}
                              fontSize={20}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </>
                )}
              />
              {errors.confirmPassword && (
                <FormHelperText sx={{ color: "error.main" }}>{errors.confirmPassword.message}</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box className='demo-space-x' sx={{ "& > :last-child": { mr: "0 !important" } }}>
            <Button
              size='large'
              variant='contained'
              sx={buttonStyles}
              onClick={handleAdd}
              disabled={!!Object.keys(errors).length || isDisabled}
            >
              Reset password
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordNotSelectedModal;
