import { handleError } from "@helpers/store-helper";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { createCase, fetchAllCases, removeDynamicsAccess, requestDynamicsAccess } from "./action";
const setError = (state, action) => {
  state.allCases = [];
  state.activeCases = [];
  state.resolvedCases = [];
  state.isCaseLoading = false;
  const message = action?.payload?.response?.data?.message;
  const statusCode = action?.payload?.response?.data?.statusCode;

  state.isDynamicsConnected = false;
  state.errorMessage = message;
  handleError(message, statusCode);
};

const setCases = (state, action) => {
  state.allCases = action?.payload;
  state.activeCases = action?.payload?.filter(item => item.status === "active");
  state.resolvedCases = action?.payload
    ?.filter(item => item.status === "resolved")
    ?.sort((a, b) => moment(b.resolvedAt).diff(moment(a.resolvedAt)));
  state.isCaseLoading = false;
  state.isDynamicsConnected = true;
};

export const appCaseManagementSlice = createSlice({
  name: "appAdvertisment",
  initialState: {
    allCases: [],
    activeCases: [],
    resolvedCases: [],
    isCaseLoading: false,
    isDynamicsConnected: false,
    errorMessage: ""
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllCases.fulfilled, setCases)
      .addCase(fetchAllCases.pending, state => {
        state.isCaseLoading = true;
      })
      .addCase(fetchAllCases.rejected, setError)
      .addCase(createCase.fulfilled, state => {
        state.isCaseLoading = false;
      })
      .addCase(createCase.pending, state => {
        state.isCaseLoading = true;
      })
      .addCase(createCase.rejected, state => {
        state.isCaseLoading = false;
      })
      .addCase(requestDynamicsAccess.fulfilled, state => {})
      .addCase(requestDynamicsAccess.pending, state => {})
      .addCase(requestDynamicsAccess.rejected, state => {})
      .addCase(removeDynamicsAccess.fulfilled, state => {
        state.isDynamicsConnected = false;
      })
      .addCase(removeDynamicsAccess.pending, state => {})
      .addCase(removeDynamicsAccess.rejected, state => {});
  }
});

export default appCaseManagementSlice.reducer;
