import { createAsyncThunk } from "@reduxjs/toolkit";
import analyticsService from "./service";
import {
  COMPANY_NOT_SELECTED,
  PROPERTY_NOT_FOUND,
  PROPERTY_NOT_SELECTED,
  WEBSITE_NOT_SELECTED
} from "@constants/response-messages";
import { fetchAccountProperties } from "../auth";
import moment from "moment";

// Thunk to fetch analytics SEO data
export const fetchAnalyticsSeo = createAsyncThunk(
  "appAnalytics/fetchAnalyticsSeo",
  async ({ date = { start: 0, end: 0 }, isCompetitors = false }, { rejectWithValue, dispatch }) => {
    try {
      const allData = !isCompetitors ? "&data[]=gsc&data[]=psi&data[]=keywords" : "";
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment(end).utc().format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await analyticsService.fetchAnalyticsSeo(startDate, endDate, allData);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === WEBSITE_NOT_SELECTED) {
        await dispatch(fetchSeoWebsites());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk to fetch PSI data
export const fetchPSI = createAsyncThunk(
  "appAnalytics/fetchPSI",
  async ({ date = { start: 0, end: 0 }, isCompetitors = false }, { rejectWithValue, dispatch }) => {
    try {
      const allData = !isCompetitors ? "&data[]=psi" : "";
      const { start, end } = date;
      const startDate = start ? `&startDate=${start}` : "";
      const endDate = end ? `&endDate=${end}` : start ? `&endDate=${start}` : "";

      if (start || end) {
        const response = await analyticsService.fetchPSI(startDate, endDate, allData);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === WEBSITE_NOT_SELECTED) {
        await dispatch(fetchSeoWebsites());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk to fetch analytics data
export const fetchAnalytics = createAsyncThunk(
  "appAnalytics/fetchAnalytics",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment(end).utc().format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (startDate || endDate) {
        const response = await analyticsService.fetchAnalytics(startDate, endDate);
        return response.data;
      }
    } catch (error) {
      if (error.response.data.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      }

      if (error.response.data.message === PROPERTY_NOT_SELECTED || error.response.data.message === PROPERTY_NOT_FOUND) {
        await dispatch(fetchAccountProperties());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Thunk to fetch SEO websites data
export const fetchSeoWebsites = createAsyncThunk("appAnalytics/fetchSeoWebsites", async (_, { rejectWithValue }) => {
  try {
    const response = await analyticsService.fetchSeoWebsites();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Thunk to post SEO website data
export const postSeoWebsite = createAsyncThunk(
  "appAnalytics/postSeoWebsite",
  async ({ domain }, { rejectWithValue, dispatch }) => {
    try {
      const response = await analyticsService.postSeoWebsite(domain);
      if (response.data.domain) {
        dispatch(fetchSeoWebsites());
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
