import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "daysFilter",
  initialState: {
    isSingleDate: false,
    isFilter: true,
    filterText: "Last month",
    days: 30,
    date: {
      start: 0,
      end: 0
    },
    reportType: "Weekly",
    reportDate: new Date(),
    reportMonth: new Date(),
    reportYear: new Date(),
    reportQuarter: new Date(),
    reportCustomDates: {
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      previousStartDate: new Date(),
      previousEndDate: new Date()
    }
  },
  reducers: {
    setDays: (state, action) => {
      state.days = action.payload;
    },
    setIsFilter: (state, action) => {
      state.isFilter = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setIsSingleDate: (state, action) => {
      state.isSingleDate = action.payload;
    },
    setText: (state, action) => {
      state.filterText = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setReportDate: (state, action) => {
      state.reportDate = action.payload;
    },
    setReportMonth: (state, action) => {
      state.reportMonth = action.payload;
    },
    setReportYear: (state, action) => {
      state.reportYear = action.payload;
    },
    setReportQuarter: (state, action) => {
      state.reportQuarter = action.payload;
    },
    setReportCustomDate: (state, action) => {
      const { currentStartDate, currentEndDate, previousStartDate, previousEndDate } = action.payload;

      state.reportCustomDates = {
        ...state.reportCustomDates,
        ...(currentStartDate && { currentStartDate }),
        ...(currentEndDate && { currentEndDate }),
        ...(previousStartDate && { previousStartDate }),
        ...(previousEndDate && { previousEndDate })
      };
    }
  }
});

export const {
  setDate,
  setDays,
  setText,
  setIsSingleDate,
  setIsFilter,
  setReportType,
  setReportDate,
  setReportCustomDate,
  setReportMonth,
  setReportYear,
  setReportQuarter
} = filterSlice.actions;
export default filterSlice.reducer;
