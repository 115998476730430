// ** React Imports
import { createContext, useEffect, useState } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** Axios
import axios from "axios";

// ** Config
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import authConfig from "src/configs/auth";
import { analyticsPath, authPath } from "src/constants/base-urls";
import { getMyRole } from "@store/apps/roles";
import { COMPANY_NOT_SELECTED, PASSWORD_NOT_SELECTED } from "../constants/response-messages";
import { getPermissionURL } from "src/helpers/getPermissionURL";

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  errorMessage: "",
  login: () => Promise.resolve(),
  redirect: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resendEmail: () => Promise.resolve(),
  verifyEmailCode: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  selectNewPassword: () => Promise.resolve()
};
const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ guestGuard, children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user);
  const [errorMessage, setErrorMessage] = useState(defaultProvider.errorMessage);
  const [loading, setLoading] = useState(defaultProvider.loading);

  // ** Hooks
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== "/") {
      const initAuth = async () => {
        setLoading(true);
        await axios
          .get(`${authPath}${authConfig.meEndpoint}`, {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          })
          .then(async response => {
            setErrorMessage("");
            setLoading(false);

            await axios
              .get(`${analyticsPath}/roles/my`, {
                withCredentials: true,
                adapter: ["xhr", "http", function myCustomAdapter(config) {}]
              })
              .then(response => {
                setUser({ role: response?.data?.roleName, permissions: response?.data?.permissions });
              })
              .catch(error => {
                setUser({ role: "", permissions: [] });
              });
          })
          .catch(error => {
            if (error?.response?.data?.message === PASSWORD_NOT_SELECTED) {
              setErrorMessage(error?.response?.data?.message);
              setUser({ role: "", permissions: [] });
            } else {
              setUser(null);
            }

            setLoading(false);

            if (!guestGuard && error?.response?.data?.statusCode === 401) {
              router.replace("/login");
            }
          });
      };
      initAuth();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = async () => {
    await axios
      .get(`${analyticsPath}/companies/select`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(async response => {
        await axios
          .get(`${analyticsPath}/accounts`, {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          })
          .then(async response => {
            if (response?.data && Object.values(response?.data).some(value => value === true)) {
              await axios
                .get(`${analyticsPath}/roles/my`, {
                  withCredentials: true,
                  adapter: ["xhr", "http", function myCustomAdapter(config) {}]
                })
                .then(response => {
                  setUser({ role: response?.data?.roleName, permissions: response?.data?.permissions });
                  response?.data?.permissions?.length && router.replace(getPermissionURL(response?.data?.permissions));
                })
                .catch(error => {
                  router.replace("/marketing/seo");
                });
            } else {
              await router.replace("/onboarding/details/");
            }
          })
          .catch(error => {
            if (error?.response?.data?.message !== PASSWORD_NOT_SELECTED) {
              toast.error("Something went wrong.", {
                duration: 2000
              });
            }
          });
      })
      .catch(async error => {
        if (error?.response?.data?.statusCode === 403 || error?.response?.data?.statusCode === 400) {
          await axios
            .get(`${analyticsPath}/companies`, {
              withCredentials: true,
              adapter: ["xhr", "http", function myCustomAdapter(config) {}]
            })
            .then(async response => {
              if (response?.data?.length) {
                await router.replace("/select-company/");
              } else {
                await router.replace("/onboarding/get-started/");
              }
            })
            .catch(error => {
              if (error?.response?.data?.message !== PASSWORD_NOT_SELECTED) {
                toast.error("Something went wrong.", {
                  duration: 2000
                });
              }
            });
        }

        if (error?.response?.data?.statusCode === 401) {
          await router.replace("/login");
        }
      });
  };

  const handleLogin = (data, errorCallback) => {
    const params = {
      email: data?.email,
      password: data?.password,
      rememberMe: data?.rememberMe
    };
    axios
      .post(`${authPath}${authConfig.loginEndpoint}`, params, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(async response => {
        await axios
          .get(`${analyticsPath}/roles/my`, {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          })
          .then(response => {
            setUser({ role: response?.data?.roleName, permissions: response?.data?.permissions });
          })
          .catch(error => {
            if (
              error?.response?.data?.message === COMPANY_NOT_SELECTED &&
              window.location.pathname !== "/select-company/"
            ) {
              window.location.replace("/select-company");
            }
            setUser({ role: "", permissions: "" });
          });
        setLoading(false);
        await handleRedirect();
      })
      .catch(error => {
        if (errorCallback) errorCallback(error);
      });
  };

  const handleSelectNewPassword = (form, errorCallback) => {
    axios
      .patch(`${authPath}${authConfig.selectPassword}`, form, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(async response => {
        await axios
          .get(`${analyticsPath}/roles/my`, {
            withCredentials: true,
            adapter: ["xhr", "http", function myCustomAdapter(config) {}]
          })
          .then(response => {
            setUser({ role: response?.data?.roleName, permissions: response?.data?.permissions });
            dispatch(getMyRole());
            window.location.replace("/account-settings/account/");
            // Redirect the user after role is set and getMyRole is successful
            // handleRedirect();
          })
          .catch(error => {
            if (
              error?.response?.data?.message === COMPANY_NOT_SELECTED &&
              window.location.pathname !== "/select-company/"
            ) {
              window.location.replace("/select-company");
            }
            setUser({ role: "", permissions: "" });
          });
      })
      .catch(error => {
        if (errorCallback) errorCallback(error);
      });
  };

  const handleLogout = async errorCallback => {
    await axios
      .get(`${authPath}${authConfig.logoutEndpoint}`, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(async response => {
        setUser(null);
        window.localStorage.removeItem("companyId");
        router.push("/login");
        setLoading(false);
      })
      .catch(err => {
        if (errorCallback) {
          errorCallback(err);
        }
      });
  };

  const handleRegister = (params, errorCallback, successCallBack) => {
    axios
      .post(`${authPath}${authConfig.signupEndpoint}`, params, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(response => {
        window.localStorage.setItem("email", JSON.stringify(params.email));
        router.replace("/verify-email");
        if (successCallBack) {
          successCallBack(response);
        }
      })
      .catch(err => {
        if (errorCallback) {
          errorCallback(err);
        }
      });
  };

  const handleVerifyEmail = (params, errorCallback) => {
    axios
      .post(`${authPath}${authConfig.verifyEmailEndpoint}`, params, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(res => {
        router.push("/login");
      })
      .catch(err => {
        if (errorCallback) {
          errorCallback(err);
        }
      });
  };
  const handleVerifyEmailCode = (code, errorCallback) => {
    axios
      .patch(
        `${authPath}${authConfig.verifyEmailEndpoint}/${code}`,
        {},
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      )
      .then(res => {
        router.push("/login");
      })
      .catch(error => {
        if (errorCallback) {
          errorCallback(error);
        }
        if (error?.response?.data?.statusCode === 409 || error?.response?.data?.statusCode === 400) {
          toast.error("You have already verified your email.", {
            duration: 2000
          });
        } else {
          toast.error("Something went wrong, please try again later", {
            duration: 2000
          });
        }
      });
  };

  const handleResendEmail = (params, errorCallback, successCallBack) => {
    axios
      .post(`${authPath}${authConfig.resendEmailEndpoint}`, params, {
        withCredentials: true,
        adapter: ["xhr", "http", function myCustomAdapter(config) {}]
      })
      .then(res => {
        if (successCallBack) {
          successCallBack(res);
        }
      })
      .catch(err => {
        if (errorCallback) {
          errorCallback(err);
        }
      });
  };

  const handleResetPassword = ({ password, token }) => {
    axios
      .patch(
        `${authPath}${authConfig.resetPasswordEndpoint}${token}`,
        { password },
        {
          withCredentials: true,
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      )
      .then(res => {
        toast.success("Password has been reset.", {
          duration: 2000
        });
        router.push("/login");
      })
      .catch(err => {
        toast.error(err.response.data.message, {
          duration: 2000
        });
      });
  };

  const handleForgotPassword = email => {
    axios
      .post(
        `${authPath}${authConfig.forgotPasswordEndpoint}`,
        { email },

        {
          adapter: ["xhr", "http", function myCustomAdapter(config) {}]
        }
      )
      .then(res => {
        toast.success("Email has been sent.", {
          duration: 2000
        });
        router.push("/check-email");
        setLoading(false);
      })
      .catch(error => {
        if (error?.response?.data?.statusCode === 409) {
          toast.error("Your email is not verified.", {
            duration: 2000
          });
        } else {
          toast.error("Email address is not found", {
            duration: 2000
          });
        }
      });
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    errorMessage,
    login: handleLogin,
    redirect: handleRedirect,
    logout: handleLogout,
    verifyEmail: handleVerifyEmail,
    resendEmail: handleResendEmail,
    register: handleRegister,
    forgotPassword: handleForgotPassword,
    resetPassword: handleResetPassword,
    selectNewPassword: handleSelectNewPassword,
    verifyEmailCode: handleVerifyEmailCode
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
