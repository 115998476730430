import { Ability, AbilityBuilder } from "@casl/ability";

export const AppAbility = Ability;

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (role, subject, permissions) => {
  const { can, rules } = new AbilityBuilder(AppAbility);
  if (!role) {
    can("read", "acl-page");
  } else if (role === "Admin") {
    can("manage", "all");
  } else {
    permissions?.length &&
      permissions?.map(permission => {
        const { permissionName, read, write } = permission;
        read && can("read", ["acl-page", permissionName]);
        write && can("write", ["acl-page", permissionName]);
      });
  }

  return rules;
};

export const buildAbilityFor = (role, subject, permissions) => {
  return new AppAbility(defineRulesFor(role, subject, permissions), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object.type
  });
};

export const defaultACLObj = {
  action: "manage",
  subject: "all"
};

export default defineRulesFor;
