// src/actions/authActions.js
import { COMPANY_NOT_SELECTED, SOCIAL_NOT_SELECTED } from "@constants/response-messages";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListMyPages } from "../auth";
import socialMediaService from "./service";
import moment from "moment";

export const fetchSocialMedia = createAsyncThunk(
  "appAnalytics/fetchSocialMedia",
  async ({ date = { start: 0, end: 0 } }, { rejectWithValue, dispatch }) => {
    try {
      const { start, end } = date;
      const utcStart = start ? moment(start).utc().format("YYYY-MM-DD") : "";
      const utcEnd = end ? moment(end).utc().format("YYYY-MM-DD") : utcStart ? utcStart : "";
      const startDate = start ? `&startDate=${utcStart}` : "";
      const endDate = end ? `&endDate=${utcEnd}` : utcStart ? `&endDate=${utcStart}` : "";

      if (start || end) {
        const response = await socialMediaService.fetchSocialMedia(startDate, endDate);

        // Check if the response array has the required headers
        const hasRequiredHeaders = response.data.some(
          item => item.header === "fb-insights" && item.header === "ig-insights"
        );

        // If the required headers are not found, dispatch getListMyPages
        if (!hasRequiredHeaders) {
          await dispatch(getListMyPages());
        }

        return response.data;
      }
    } catch (error) {
      if (error.response?.data?.message === COMPANY_NOT_SELECTED) {
        window.location.replace("/select-company/");
      } else if (error.response?.data?.message === SOCIAL_NOT_SELECTED) {
        await dispatch(getListMyPages());
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
