// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const appOnboardingFormSlice = createSlice({
  name: "appOnboardingForm",
  initialState: {
    firstName: "",
    lastName: "",
    phone: "",
    position: "",
    businessType: "",
    business: "",
    company: "",
    contactNumber: "",
    state: "",
    address: "",
    url: "",
    isValid: true,
    isError: false,
    isSelectedCompany: false
  },
  reducers: {
    changeFormValue: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },

    setFormInitialValue: (state, action) => {
      return { ...state, ...action.payload };
    },

    setFormIsValid: (state, action) => {
      state.isValid = action.payload;
    },

    setShowErrorMessage: (state, action) => {
      state.isError = action.payload;
    }
  }
});

export const { changeFormValue, setFormIsValid, setShowErrorMessage, setFormInitialValue } =
  appOnboardingFormSlice.actions;

export default appOnboardingFormSlice.reducer;
